import {
  SimpleForm,
  TextInput,
  Edit,
  required,
  ReferenceInput,
  SelectInput,
  minLength,
} from "react-admin";
import { ActionsBack } from "../../../components/ActionsBack";
import { hasPermission } from "../../../utils/permissions";

export const UserEdit = props => {
  return (
    <Edit
      {...props}
      title=" "
      actions={<ActionsBack />}
      component="div"
    >
      <SimpleForm
        redirect='list'
        variant='filled'
        margin='dense'
      >
        <TextInput
          source='name'
          label='Nombre'
          validate={required()}
        />
        <TextInput
          source='email'
          label='Email'
          type="email"
          autoComplete="username"
          validate={[required()]}
        />
        <TextInput
          source='phone_number'
          label='Teléfono'
          type="phone"
        />
        <TextInput
          source='password'
          label='Contraseña'
          type="password"
          autoComplete="current-password"
          validate={minLength(6)}
        />
        {hasPermission('edit-roles') &&
          <ReferenceInput label="Rol" source="roles" reference="roles">
            <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
        }
      </SimpleForm>
    </Edit>
  );
};
