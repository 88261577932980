import {
  List,
  Datagrid,
  TextField,
  NumberField,
} from "react-admin";
import { FactoryFilter } from "./FactoryFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { ListActions } from "../../../components/ListActions";

export const FactoryList = (props) => {
  return (
    <List
      {...props}
      empty={<EmptyPage resourceName="Plantas" />}
      title=" "
      perPage={10}
      sort={{ field: "name", order: "DESC" }}
      filters={<FactoryFilter />}
      bulkActionButtons={false}
      actions={<ListActions labelButton="Agregar Planta" />}
    >
      <Datagrid title="Plantas" rowClick="edit">
        <TextField source="name" label="Nombre" />
        <TextField source="code" label="Código" />
        <TextField source="rut" label="RUT" />
        <TextField source="address" label="Dirección" />
        <TextField source="in_charge_email" label="Email" />
        <TextField source="in_charge_phone_number" label="Teléfono" />
        <NumberField source="prescriptions_count" label="Recetas" />
      </Datagrid>
    </List>
  );
};
