import {
  Box,
  makeStyles,
  CardContent,
  Card,
  CardHeader,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
  aside: {
    minWidth: "250px",
    marginLeft: "1.5em",
  },
  subtitle: {
    color: "#5B5B5B",
    fontSize: "0.9em",
  },
  numberCount: {
    color: "#007DC3",
    fontSize: "1.5em",
    fontWeight: 600,
  },
  card: {
    borderRadius: "10px",
  },
  mt: {
    marginTop: "1.5em",
  },
  color: {
    color: "#007DC3",
    textDecoration: "none",
    cursor: "pointer",
  },
  info: {
    color: "#5B5B5B",
    fontSize: "0.9em",
    textTransform: "capitalize",
  },
  mg: {
    marginTop: "8px",
  },
});

export const ClientAside = ({ props }) => {
  const classes = useStyle();
  const record = useRecordContext();
  if (!record) return null;

  const { estates, prescriptions } = record;

  return (
    <Box className={classes.aside}>
      <Card className={classes.card}>
        <CardHeader title='Historial de recetas' titleTypographyProps={{ style: { fontWeight: 600 } }} />
        <CardContent>
          <Box display='flex' justifyContent='space-around'>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <span className={classes.numberCount}>{estates?.length}</span>
              <span className={classes.subtitle}>Predio{estates?.length !== 1 ? "s" : ""}</span>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <span className={classes.numberCount}>{prescriptions?.length}</span>
              <span className={classes.subtitle}>Receta{prescriptions?.length !== 1 ? "s" : ""}</span>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {prescriptions?.length > 0 ? (
        <Card className={`${classes.card} ${classes.mt}`}>
          <Stepper orientation='vertical'>
            {prescriptions?.slice(0)?.reverse()?.map(prescription => (
              <Step key={`${prescription.id}`} active expanded>
                <StepLabel
                  StepIconComponent={() => (
                    <AssignmentIcon className={classes.color} />
                  )}
                >
                  <Box display='flex' flexDirection='column'>
                    <Link
                      to={`/recetas/${prescription.id}/show`}
                      className={`${classes.color} ${classes.mg}`}
                    >
                      {new Date(prescription.created_at).getDate()} <span style={{textTransform: 'capitalize'}}>{new Date(prescription.created_at).toLocaleString('es-AR',{month: "long"})}</span> {new Date(prescription.created_at).getFullYear()}
                    </Link>
                    <span className={classes.info}>
                      {prescription?.estate?.name}
                    </span>
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Card>
      ) : null}
    </Box>
  );
};
