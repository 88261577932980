import { useDispatch } from "react-redux";
import {
  showNotification,
  UPDATE,
  SaveButton,
  useRedirect,
  useEditContext,
} from "react-admin";
import { useFormState } from "react-final-form";
export const SaveEdit = ({resourcefetch  =  null, value = 'name', labelSave = 'GUARDAR CAMBIOS' ,...props}) =>{
    const { resource } = useEditContext();
    const src = resourcefetch ? resourcefetch : resource;
    const useredirect = useRedirect();
    const dispatch = useDispatch();
    const { values } = useFormState();

    const handleSave = () => {
        dispatch({
          type: "PUT",
          payload: { id: values.id, data: values },
          meta: {
            fetch: UPDATE,
            resource: src,
            onSuccess: {
              callback: ({ payload: { data } }) => {
                dispatch(showNotification(data[value] + " modificado con éxito", "info"));
                useredirect(props?.basePath);
              },
            },
            onFailure: {
              callback: ({ message }) => {
                let errorMessages = [];
                Object.keys(message).forEach(key => {
                  errorMessages.push(message[key]);
                });
                errorMessages = errorMessages.join(',');
                dispatch(showNotification(errorMessages, "error"));
              },
            },
          },
        });
    }
    return (
      <SaveButton handleSubmitWithRedirect={handleSave} label={labelSave}/>
    )
  }