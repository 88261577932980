import { Typography } from "@material-ui/core";
import { Edit, SimpleForm, TextInput, required } from "react-admin";
import { SaveEdit } from "../../components/SaveEdit";
import { Box } from "@material-ui/core";
import { hasRole } from "../../utils/permissions";

export const AccountConfig = ({ ...props }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const validateUserActualization = (values) => {
    const errors = {};
    // eslint-disable-next-line
    if (values?.password != values?.password_confirmation) {
        errors.password = 'Las contraseñas deben coincidir';
        errors.password_confirmation = 'Las contraseñas deben coincidir';
    }
    if (values?.password?.length < 6 ){
      errors.password = 'La contraseña debe tener al menos 6 caracteres';
    }
    return errors
};
  return (
    <Edit
      {...props}
      id={user?.id}
      resource="usuarios"
      redirect={null}
      title=" "
      basePath="/configuracion"
        style={{maxWidth:'900px'}}
    >
      <SimpleForm
        redirect={null}
        variant="standard"
        margin="dense"
        toolbar={null}
        validate={validateUserActualization}
      >
        <Box display="flex" fullWidth justifyContent="space-between">
          <Typography component="span" variant="h6">
            Mi perfil
          </Typography>
          <SaveEdit resourceName={"Usuario"} id={user?.id}/>
        </Box>
        <TextInput source="name" label="Nombre" validate={required()} />
        <TextInput
          source="email"
          label="Email"
          type="email"
          validate={[required()]}
        />
        {hasRole("Cliente") && 
          <TextInput source="rut" label="RUT" validate={[required()]} />
        }
        <TextInput
          source="phone_number"
          label="Teléfono"
          type="phone"
        />
        <TextInput source="password" label="Contraseña Nueva" type="password" minLength={6} />
        <TextInput source="password_confirmation" label="Confirmar Contraseña" type="password" minLength={6} />
      </SimpleForm>
    </Edit>
  );
};
