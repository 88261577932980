import {
  SimpleForm,
  TextInput,
  Create,
  required,
  ReferenceInput,
  SelectInput,
  useNotify,
  minLength,
} from "react-admin";
import { ActionsBack } from "../../../components/ActionsBack";
import { hasPermission } from "../../../utils/permissions";

export const UserCreate = (props) => {
  const notify = useNotify();
  const onFailure = ({ message }) => {
    let errorMessages = [];
    Object.keys(message).forEach((key) => {
      errorMessages.push(message[key]);
    });
    errorMessages = errorMessages.join(",");
    notify(errorMessages, { type: "error" });
  };
  return (
    <Create
      {...props}
      title=" "
      actions={<ActionsBack />}
      component="div"
      redirect="list"
      onFailure={onFailure}
    >
      <SimpleForm redirect="list" variant="filled" margin="dense">
        <TextInput source="name" label="Nombre" validate={required()} />
        <TextInput
          source="email"
          label="Email"
          type="email"
          validate={[required()]}
          options={{ autoComplete: "off" }}
        />
        <TextInput source="phone_number" label="Teléfono" type="phone" />
        <TextInput
          source="password"
          label="Contraseña"
          type="password"
          options={{ autoComplete: "new-password" }}
          validate={[required(),minLength(6)]}
        />
        {hasPermission("edit-roles") && (
          <ReferenceInput label="Rol" source="roles" reference="roles">
            <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  );
};
