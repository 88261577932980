import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  useRecordContext,
  ReferenceField,
} from "react-admin";
import { PrescriptionFilter } from "./PrescriptionFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { ListActions } from "../../../components/ListActions";
import { makeStyles } from "@material-ui/core/styles";
import { hasRole } from "../../../utils/permissions";

const useStyles = makeStyles({
  sent: { color: "#1EBA82" },
  notSent: { color: "#FF8947" },
  invalid: { color: "#C42A21" },
});

const PrescriptionStatusField = () => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <FunctionField
      render={(record) =>
        record.status === "sent"
          ? "ENVIADA"
          : record.status === "invalid"
          ? "ANULADA"
          : "SIN ENVIAR"
      }
      className={
        record.status === "sent"
          ? classes.sent
          : record.status === "invalid"
          ? classes.invalid
          : classes.notSent
      }
    />
  );
};

export const PrescriptionList = (props) => {
  return (
    <List
      {...props}
      empty={<EmptyPage resourceName="Recetas" hasCreate={false} />}
      title=" "
      perPage={10}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<PrescriptionFilter />}
      bulkActionButtons={false}
      actions={<ListActions hasCreate={false} hasExcelExport />}
    >
      <Datagrid title="Recetas" rowClick={"show"}>
        <DateField
          locales="es-ES"
          source="created_at"
          label="Fecha de prescripción"
        />
        <TextField
          label="Código único"
          source="prescription_number"
        />
        <PrescriptionStatusField label="Estado" />
        <ReferenceField
          label="Veterinario"
          source="veterinarian_id"
          reference="veterinarios"
        >
          <FunctionField
            render={(record) => `${record.first_name} ${record.last_name}`}
          />
        </ReferenceField>
        {hasRole("superadmin") && (
          <ReferenceField
            label="Cliente"
            source="estate.client_id"
            reference="clientes"
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <ReferenceField
          label="Predio"
          source="estate_id"
          reference="predios"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Planta/Fábrica"
          source="factory_id"
          reference="plantas"
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
