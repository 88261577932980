import { Route } from 'react-router-dom';
import CustomRegister from "../components/CustomRegister";
import CustomRecover from '../components/CustomRecover';
import { AccountConfig } from '../resources/config/config';
import CustomReset from '../components/CustomReset';
export const PublicRoutes = {
    SIGNUP: "registrarse",
    RECOVER: "recuperar-password",
    RESET: "reset-password"
}

export const customRoutes = [
     <Route exact path={`/${PublicRoutes.SIGNUP}`} noLayout render={(props)=>(<CustomRegister {...props}/>)}  />,
     <Route exact path={`/${PublicRoutes.RECOVER}`} noLayout render={(props)=>(<CustomRecover {...props}/>)}  />,
     <Route exact path={`/${PublicRoutes.RESET}`} noLayout render={(props)=>(<CustomReset {...props}/>)}  />,
     <Route exact path={'/configuracion'} render={(props) => (<AccountConfig {...props}/>)}/>
]