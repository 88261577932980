import * as React from "react";
import { useState } from "react";
import { Form } from "react-final-form";
import { useLocation } from "react-router-dom";
import Logo from "../Logo";
import { Button, Card, CardActions, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  Notification,
  useTranslate,
  useLogin,
  useNotify,
  PasswordInput,
  TextInput,
  Link,
} from "react-admin";
import loginBg from "../assets/login-bg.png";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background: `url(${loginBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "75%",
    backgroundSize: "cover",
  },
  card: {
    maxWidth: 400,
    marginTop: "6em",
    padding: "32px 16px 32px 16px",
    background: "rgba(255,255,255,0.9)",
    borderRadius: "10px",
  },
  avatar: {
    margin: "1em 1em 3em 1em",
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    backgroundColor: "#007DC3",
    "&:hover": {
      backgroundColor: "#007DC3",
    },
  },
  hint: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 0 1em",
  },
  actions: {
    padding: "1.5em 1em 1em 1em",
    display: "flex",
    flexDirection: "column",
    position:'relative',
  },
  link: {
    alignSelf: "flex-end",
    position: 'absolute',
    top: '-15px',
    zIndex: 2,
    cursor: 'pointer'
  },
  spacing: {
    marginRight: "1em",
  },
  input: {
    "& .MuiInputBase-input": {
      color: "#000", // Text color
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#007DC3",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "#000", // Semi-transparent underline
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottomColor: "#007DC3", // Solid underline on hover
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#007DC3", // Solid underline on focus
    },
  },
}));

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : "/").catch(
      (error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning",
          {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          }
        );
      }
    );
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Logo width={"250px"} />
              </div>
              <div className={classes.form}>
                <TextInput
                  name="username"
                  source="username"
                  label={translate("ra.auth.email")}
                  disabled={loading}
                  className={classes.input}
                  fullWidth
                />
                <PasswordInput
                  name="password"
                  source="password"
                  label={translate("ra.auth.password")}
                  disabled={loading}
                  className={classes.input}
                  fullWidth
                />
              </div>
              <CardActions className={classes.actions}>
                <Link to="/recuperar-password" className={classes.link}>
                  Olvidé mi contraseña
                </Link>
                <Button
                  className={classes.icon}
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                      className={classes.spacing}
                    />
                  )}
                  {translate("ra.auth.sign_in")}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const CustomLogin = (props) => (
  <ThemeProvider>
    <Login {...props} />
  </ThemeProvider>
);

export default CustomLogin;
