import * as React from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import Abilities from './abilities';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold,
    },
}));

const PanelTitle = ({ record }) => {
    const classes = useStyles();
    if (record) {
        return (
            <Typography className={classes.heading}>
                Crear nuevo Rol
            </Typography>
        );
    }
}

export const RoleCreate = props => (
    <Create  title=" " {...props} redirect="list" >
        <SimpleForm >
            <PanelTitle {...props} />
            <TextInput source="name" label="Nombre" validate={[required()]} />
            <Abilities source="abilities" {...props} />
        </SimpleForm>
    </Create>
);