import {
  List,
  Datagrid,
  TextField,
  NumberField,
  SelectField,
  useRecordContext,
} from "react-admin";
import { VeterinarianFilter } from "./VeterinarianFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { ListActions } from "../../../components/ListActions";
import { Fragment } from "react";
import { ApproveButton } from "./ApproveButton";
import { RejectedButton } from "./RejectButton";
import { makeStyles, Box } from "@material-ui/core";
import { hasRole } from "../../../utils/permissions";
import { EstatesPanel } from "./EstatesPanel";
const CHOICES = [
  { id: "approved", name: "APROBADO" },
  { id: "pending", name: "PENDIENTE" },
  { id: "rejected", name: "RECHAZADO" },
];

const BulkAction = ({ ...props }) => {
  return (
    <Fragment>
      <Box mr={1}>
        <ApproveButton {...props} />
      </Box>
      <RejectedButton {...props} />
    </Fragment>
  );
};

const useStyles = makeStyles({
  sent: { color: "#1EBA82" },
  rejected: { color: "#C32A21" },
  pending: { color: "#FF8947" },
});

const CustomSelect = () => {
  const classes = useStyles();
  const record = useRecordContext();
  return (
    <SelectField
      source="status"
      choices={CHOICES}
      className={
        record?.status === "approved"
          ? classes.sent
          : record?.status === "rejected"
          ? classes.rejected
          : classes.pending
      }
    />
  );
};

export const VeterinarianList = (props) => {
  return (
    <List
      {...props}
      empty={<EmptyPage resourceName="Veterinarios" />}
      title=" "
      perPage={10}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<VeterinarianFilter />}
      bulkActionButtons={hasRole('superadmin') ? <BulkAction /> : false}
      actions={<ListActions hasCreate={false} />}
    >
      <Datagrid title="Veterinarios" expand={hasRole("Cliente") ? <EstatesPanel /> : null} rowClick={"show"}>
        <TextField source="username" label="Nombre y Apellido" />
        <CustomSelect label="Estado" />
        {hasRole("superadmin") && <TextField source="email" label="Email" />}
        {hasRole("superadmin") && <TextField source="rut" label="RUT" />}
        {hasRole("Cliente") && (
          <NumberField source="estates_count" label="Predios" />
        )}
        <NumberField source="prescriptions_count" label="Recetas" />
      </Datagrid>
    </List>
  );
};
