import {
  SimpleForm,
  TextInput,
  Edit,
  required,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  ListButton,
  UrlField
} from "react-admin";
import { makeStyles, Typography} from "@material-ui/core";
import { ActionsBack } from "../../../components/ActionsBack";

const useStyle = makeStyles({
  title: {
    fontSize: '1.6em',
    fontWeight: 500,
    marginBottom: '1em'
  },
  cancelButton: {
    marginRight: '2em'
  },
  fileInput: {
    maxWidth: "300px"
  }
});

const ProductEditToolbar = (props) => {
  const classes = useStyle();

  return (
  <Toolbar {...props}>
    <ListButton icon={null} className={classes.cancelButton} variant="outlined" size="medium" label="cancelar" basePath={props.basePath} />
    <SaveButton
      label="Guardar cambios"
      redirect="list"
      submitOnEnter={false}
    />
  </Toolbar>
)};

export const ProductEdit = (props) => {
  const classes = useStyle();

  return (
    <Edit {...props} component='div'  title=" " actions={<ActionsBack {...props}/>}>
      <SimpleForm toolbar={<ProductEditToolbar />}>
        <Typography className={classes.title}>
          Editar etiqueta
        </Typography>
        <TextInput source="name" label="Nombre del medicamento" validate={[required()]} />
        <TextInput source="active_principle" label="Principio activo" />
        <TextInput source="register_number" label="N° Reg SAG" />
        <UrlField source="data_sheet_url" label="Ficha técnica actual"/>
        <FileInput className={classes.fileInput} source="data_sheet_raw" label="Editar ficha técnica" accept="application/pdf">
          <FileField source="src" title="title" />
        </FileInput>
        <UrlField source="doses_url" label="Tabla de dosis actual"/>
        <FileInput className={classes.fileInput} source="doses_raw" label="Editar tabla de dosis" accept="image/jpg,image/bmp,image/png">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  )
};
