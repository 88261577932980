import {
  List,
  Datagrid,
  TextField,
  EmailField,
  useRecordContext
} from "react-admin";
import { ClientFilter } from "./ClientFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { ListActions } from "../../../components/ListActions";

const Estates = () =>{
  const record = useRecordContext();
  const cont = record?.clients?.reduce((acc,current)=>(acc + current?.estates?.length),0) ;
  return (
    <span>{cont}</span>
  )
}

const Prescription = () =>{
  const record = useRecordContext();
  const cont = record?.clients?.reduce((acc,current)=>(acc + (current?.prescriptions?.length || 0 )),0);
  return (
    <span>{cont}</span>
  )
}

export const ClientList = props => {
  return (
    <List
      {...props}
      empty={<EmptyPage resourceName='Clientes' />}
      title=" "
      perPage={10}
      sort={{ field: "name", order: "ASC" }}
      filters={<ClientFilter />}
      bulkActionButtons={false}
      actions={<ListActions hasCreate={false}/>}
    >
      <Datagrid title='Clientes' rowClick={null}>
        <TextField source="name" label="Cliente" />
        <EmailField source="email" label="Email" />
        <TextField source="rut" label="RUT" />
        <TextField source="phone_number" label="Teléfono" />
        <Estates label="Predios" />
        <Prescription label="Recetas" />
      </Datagrid>
    </List>
  );
};
