


import { Admin } from "react-admin";
import adminProps from "./config/admin";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { messages } from './language/language-es';
import { Resources } from './config/Resources';
import { customRoutes } from "./utils/customRoutes";

const App = () => {
  const i18nProvider = polyglotI18nProvider(() => messages, "es", {
    allowMissing: true,
  });
  return (
    <Admin {...adminProps} i18nProvider={i18nProvider} customRoutes={customRoutes}>
     {() => Resources()}
    </Admin>
  );
};

export default App;


