import { makeStyles } from "@material-ui/core";
import { Filter, SearchInput } from "react-admin";

const useStyles = makeStyles({
  search: {
    '& input[name="search"]':{
      paddingTop: '18px',
    }
  }
})

export const FactoryFilter = props => {
  const classes = useStyles();
  return(
  <Filter {...props} variant='outlined'>
    <SearchInput source='search' alwaysOn variant="filled" className={classes.search}/>
    
  </Filter>
  );
}
