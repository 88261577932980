import { stringify } from 'query-string';

import { apiUrl, httpClient, sendAsFormData } from '../utils/commosHttp'

const dictionary  = new Map([
    ['usuarios','users'],
    ['veterinarios','veterinarians'],
    ['recetas','prescriptions'],
    ['clientes','clients'],
    ['predios','estates'],
    ['plantas','factories'],
    ['roles','roles'],
    ['habilidades','abilities'],
    ['etiquetas','products'],
]) 

const translate = (resource) =>{
    let result =dictionary.get(resource);
    if (result) 
        return result;
    return resource;
}

export const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            sortBy: field,
            sortType: order,
            page: page,
            size: perPage,
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${translate(resource)}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data,
            total: json.total,
        }));
    },

    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${translate(resource)}/${params.id}`).then(({ json }) => {
            return ({
            data: json,
        })})
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${translate(resource)}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.data }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sortBy: field,
            sortType: order,
            page: page,
            size: perPage,
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${translate(resource)}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data,
            total: json.total,
        }));
    },

    update: (resource, params) =>{
        if (resource === 'etiquetas') {
            return sendAsFormData('PUT', 'products', params)
        }
        return httpClient(`${apiUrl}/${translate(resource)}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
    
    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${translate(resource)}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) =>({ data: json }));
    },

    create: (resource, params) => {
        if (resource === 'etiquetas') {
            return sendAsFormData('POST', 'products', params)
        }

        return httpClient(`${apiUrl}/${translate(resource)}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${translate(resource)}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${translate(resource)}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
    sendToProduction: (resource, params) => {
        return httpClient(`${apiUrl}/${translate(resource)}/${params.id}/send-to-production`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { json },
        }));
    },
};
