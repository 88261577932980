import React from "react";
import { Box, Card, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        background: '#fff',
        padding: 20,
        marginTop: theme.spacing(2),
        marginBottom: '16px',
        textAlign: 'center',
    },
    icon: {
        width: '5em',
        height: '5em',
    },
}));

export const NonPermission = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Title title="No tienes permiso" />
            <Box display="flex">
                <Box flex="1">
                    <LockIcon className={classes.icon} />
                    <Typography variant="h5" component="h2" gutterBottom>
                     Lo siento, no tienes permiso para realizar esta acción
                    </Typography>
                    <Box>
                        <Typography variant="body1" component="p" gutterBottom>
                            Póngase en contacto con el administrador del sitio para obtener los permisos necesarios.
                      </Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};
