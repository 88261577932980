import { useState } from "react";
import { MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import { hasPermission, hasRole } from "../utils/permissions";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import SubMenu from "./SubMenu";

export const Menu = ({ onMenuClick, dense = false }) => {
  const [state, setState] = useState({
    usersMenu: false,
  });
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box mt={3}>
      {hasPermission("view-dashboard") && (
        <MenuItemLink
          to={`/`}
          primaryText={"Home"}
          leftIcon={<HomeRoundedIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          exact
        />
      )}
      {/* Recetas */}
      {hasPermission("list-prescriptions") && (
        <MenuItemLink
          to={`/recetas`}
          primaryText={"Recetas"}
          leftIcon={<AssignmentTurnedInIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          exact
        />
      )}
      {/* Veterinarios */}
      {hasPermission("list-veterinarians") && (
        <MenuItemLink
          to={`/veterinarios`}
          primaryText={"Veterinarios"}
          leftIcon={<LocalHospitalIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          exact
        />
      )}

      {/* Predios */}
      {hasPermission("list-estates") && hasRole("Cliente") && (
        <MenuItemLink
          to={`/predios`}
          primaryText={"Predios"}
          leftIcon={<LocationOnOutlinedIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          exact
        />
      )}

      {/* Fabricas */}
      {hasPermission("list-factories") && (
        <MenuItemLink
          to={`/plantas`}
          primaryText={"Plantas"}
          leftIcon={<BusinessIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          exact
        />
      )}
      {/* Clientes */}
      {hasPermission("list-clients") && (
        <MenuItemLink
          to={`/clientes`}
          primaryText={"Clientes"}
          leftIcon={<PeopleIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          exact
        />
      )}
      {/* Gestión de etiquetas */}
      {hasPermission("list-products") && (
        <MenuItemLink
          to={`/etiquetas`}
          primaryText={"Gestión de etiquetas"}
          leftIcon={<AssignmentOutlinedIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          exact
        />
      )}
      {(hasPermission("list-users") || hasPermission("list-roles")) && (
        <SubMenu
          handleToggle={() => handleToggle("usersMenu")}
          isOpen={state.usersMenu}
          sidebarIsOpen={open}
          name="Usuarios"
          icon={<AccountCircleIcon />}
          dense={dense}
        >
          {hasPermission("list-users") && (
            <MenuItemLink
              to={`/usuarios`}
              primaryText={"Listado"}
              leftIcon={<ViewListIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              role="submenu"
              dense={dense}
            />
          )}
          {hasPermission("list-roles") && (
            <MenuItemLink
              to={`/roles`}
              primaryText={"Roles y permisos"}
              leftIcon={<SupervisedUserCircleIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              role="submenu"
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      <MenuItemLink
        to={`/configuracion`}
        primaryText={"Configuración"}
        leftIcon={<SettingsIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        exact
      />
    </Box>
  );
};
