import { cloneElement } from 'react';
import { TopToolbar, CreateButton } from 'react-admin';
import ExcelExportButton from './ExcelExportButton';

export const ListActions = props => {
  return (
    <TopToolbar>
      {cloneElement(props.filters, { context: 'button' })}
      {props.hasCreate && (
        <CreateButton variant='contained' label={props.labelButton} />
      )}
      {props.hasExcelExport && (
        <ExcelExportButton />
      )}
    </TopToolbar>
  );
};
