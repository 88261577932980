import { Toolbar } from 'react-admin';
import  BackButton  from "./BackButton";
import { SaveCreate } from './SaveCreate';
import { SaveEdit } from "./SaveEdit";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  button:{
    borderColor: '#007DC3',
    marginRight: '2em',
    cusor: 'pointer',
    background: 'white',
    opacity: 0.9,
    '&:hover':{
        background: 'white',
        opacity: 1
    }
  }
})

export const CustomToolbar = ({labelSave = "Guardar",labelBack="Cancelar",...props}) =>{
  const classes = useStyles();
  /** if have props in record is a edit form else is a create form */
  return (
    <Toolbar>
      <BackButton variant="outlined" className={classes.button}>{labelBack}</BackButton>
      {Object.keys(props?.record)?.length > 0 ? <SaveEdit labelSave={labelSave} {...props}/> : <SaveCreate labelSave={labelSave} {...props}/>}
    </Toolbar>
  )
}
