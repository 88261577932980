import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  List,
  ListButton,
  useListContext,
} from "react-admin";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { EmptyPage } from "../../../components/EmptyPage";

const useStyle = makeStyles({
  card: {
    display: "inline-block",
    margin: "1em 1.5em 1em 0",
    padding: "0.2em 2.5em",
    borderRadius: "10px",
  },
  title: {
    fontSize: "26px",
    fontWeight: 500,
    marginBottom: "0.3rem",
    color: "#000",
  },
  subTitle: {
    fontWeight: 400,
  },
});

const EstateCards = () => {
  const classes = useStyle();
  const { ids, data } = useListContext();

  return ids.length > 0 && (
    <Box margin='1em'>
      <Typography className={classes.title} variant='h5'>
        Mis Predios
      </Typography>
      {ids.map(id => (
        <Card key={id} elevation={4} className={classes.card}>
          <CardContent>
            <Typography className={classes.title} variant='h5' align='center'>
              {data[id].name}
            </Typography>
            <Typography
              className={classes.subTitle}
              variant='body1'
              align='center'
              gutterBottom
            >
              {data[id].prescriptions_count || 0} recetas generadas
            </Typography>
            <Box display='flex' justifyContent='center' marginTop='0.8em'>
              <ListButton
                icon={<VisibilityIcon />}
                label='ver predio'
                to={{
                  pathname: "/recetas",
                  search: `filter=${JSON.stringify({ estate_id: id })}`,
                }}
              />
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export const EstateList = props => (
  <List
    {...props}
    title=" " 
    component='div'
    empty={<EmptyPage resourceName='Predios' hasCreate={false}/>}
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
    actions={null}>
    <EstateCards />
  </List>
);
