import {
  CreateButton,
  useListContext,
} from "react-admin";
import {Typography,Box} from '@material-ui/core';
import { Fragment } from "react";

export const EmptyPage = ({ resourceName,hasCreate,...props }) => {
  const { basePath } = useListContext();
  return (
    <Box textAlign='center' m={1} mt='4em'>
      <Typography variant='h4' paragraph>
        No existen {resourceName} todavía
      </Typography>
      {hasCreate && 
      <Fragment>
        <Typography variant='body1'>Ingrese el primer registro</Typography>
        <CreateButton basePath={basePath} />
      </Fragment>
      }
    </Box>
  );
};
