import {
  ImageField,
  Labeled,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useUpdate,
  useRefresh,
  useNotify,
  useRedirect,
} from "react-admin";
import {
  Box,
  makeStyles,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  TextField as MuiText,
} from "@material-ui/core";
import { AsidePrescriptions } from "../../../components/AsidePrescriptions";
import { hasPermission } from "../../../utils/permissions";
import { Fragment, useState } from "react";

const useStyle = makeStyles({
  main: {
    "& .MuiCardContent-root": {
      paddingTop: "0 !important",
    },
  },
  mainCard: {
    borderRadius: "10px",
    padding: "1em",
  },
  mainCardHeader: {
    padding: "0",
  },
  text: {
    color: "black",
    fontWeight: 500,
    fontSize: "1.1em",
  },
  green: {
    color: "#1EBA82",
  },
  yellow: {
    color: "#FF8947",
  },
  danger: {
    color: "#C32A21",
  },
  buttonoutile: {
    borderColor: "#007DC3",
    marginRight: "1em",
  },
  buttonoutileWarning: {
    borderColor: "red",
    color: "red",
    marginRight: "1em",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  dialogAction: {
    paddingRight: "1.5em",
  },
  titleDialog: {
    fontSize: "18px!important",
    fontWeight: "500!important",
    lineHeight: "20px!important",
  },
  dialogActions: {
    padding: "0 16px 16px 16px!important",
  },
  dialogContent: {
    padding: "16px!important",
  },

  pointer: {
    cursor: "pointer",
  },
  rejectedText:{
    width: "350px",
    margin:'16px 0',
    "& .MuiFilledInput-root": { padding: "8px!important" },
    "& .MuiFilledInput-multiline":{ padding: "8px!important" },
  }
});

const CHOICES = [
  { id: "approved", name: "Aprobado" },
  { id: "pending", name: "Pendiente" },
  { id: "rejected", name: "Rechazado" },
];
const CustomSelect = () => {
  const classes = useStyle();
  const record = useRecordContext();
  return (
    <SelectField
      source="status"
      choices={CHOICES}
      className={`${classes.text} ${
        record?.status === "approved"
          ? classes.green
          : record?.status === "pending"
          ? classes.yellow
          : classes.danger
      }`}
    />
  );
};

const AprobeRegister = () => {
  const classes = useStyle();
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [update, { loading }] = useUpdate(
    "veterinarians",
    record.id,
    {
      status: "approved",
      verification_token: null,
      validation_date: new Date().toISOString().slice(0, 19).replace("T", " "),
    },
    record,
    {
      onSuccess: () => {
        record.status = "approved";
        refresh();
        notify("Veterinario actualizado", { type: "success" });
      },
      onError: (error) =>
        notify("No se pudo actualizar el estado del veterinario", {
          type: "warning",
        }),
    }
  );
  const handleClick = () => {
    update();
  };
  if (!record || record?.status === "approved") return null;
  return loading ? (
    <Box
      display="inline-flex"
      gridGap="1em"
      alignItems="center"
      marginRight="2em"
    >
      <Typography color="primary" variant="button">
        Aprobando...
      </Typography>
      <CircularProgress color="primary" size={20} />
    </Box>
  ) : (
    <Button
      disabled={loading}
      variant="outlined"
      className={classes.buttonoutile}
      onClick={() => handleClick()}
    >
      APROBAR
    </Button>
  );
};

const RejectedRegister = () => {
  const classes = useStyle();
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState();
  const refresh = useRefresh();
  const notify = useNotify();
  const [update, { loading }] = useUpdate(
    "veterinarians",
    record.id,
    {
      status: "rejected",
      verification_token: null,
      message: text,
      validation_date: new Date().toISOString().slice(0, 19).replace("T", " "),
    },
    record,
    {
      onSuccess: () => {
        record.status = "rejected";
        refresh();
        notify("Estado actualizado correctamente", { type: "info" });
      },
      onError: (error) =>
        notify("No se pudo actualizar el estado del veterinario", {
          type: "warning",
        }),
    }
  );
  const handleSave = () => {
    setOpen(false);
    update();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (!record || record?.status === "rejected") return null;
  return loading ? (
    <Box
      display="inline-flex"
      gridGap="1em"
      alignItems="center"
      marginRight="2em"
    >
      <Typography color="primary" variant="button">
        Actualizando...
      </Typography>
      <CircularProgress color="primary" size={20} />
    </Box>
  ) : (
    <Fragment>
      {
       hasPermission('edit-veterinarians') && 
        <Button
          disabled={loading}
          variant="outlined"
          className={classes.buttonoutileWarning}
          onClick={() => handleClick()}
        >
          RECHAZAR
        </Button>
      }
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.titleDialog}>
            ¿Está seguro que desea rechazarlo?
          </Typography>
          <MuiText
            value={text}
            onChange={(e) => setText(e.target.value)}
            variant="filled"
            placeholder="Motivos del rechazo"
            multiline
            rows={2}
            maxRows={4}
            className={classes.rejectedText}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button onClick={handleClose}>CANCELAR</Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              ACEPTAR
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const VeterinarianImageField = ({ source, label }) => {
  const classes = useStyle();
  const record = useRecordContext();

  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  return (
    <Fragment>
      <ImageField
        source={source}
        label={label}
        onClick={handleClick}
        className={classes.pointer}
      />
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle className={classes.pt}>
          <Typography variant="body2">
            Imagen de validación de profesión
          </Typography>
        </DialogTitle>
        <DialogContent>
          <img
            className={classes.image}
            src={record.certificate_url}
            alt="certificate"
          />
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogClose}
          >
            cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export const VeterinarianShow = (props) => {
  const classes = useStyle();
  const redirect = useRedirect();
  const handleClick = (e) => {
    redirect(`/veterinarios/${props.id}`);
  };
  return (
    <Show
      {...props}
      component="div"
      actions={null}
      aside={<AsidePrescriptions />}
      className={classes.main}
      title=" "
    >
      <SimpleShowLayout redirect="list" toolbar={null}>
        <div>
          <Card className={classes.mainCard}>
            <Box
              margin="1em 1em 2em"
              display="flex"
              justifyContent="space-between"
            >
              <CardHeader
                className={classes.mainCardHeader}
                title="Ficha del Veterinario"
                titleTypographyProps={{ style: { fontWeight: 600 } }}
              />
              <Box display="flex">
                <RejectedRegister />
                <AprobeRegister />
                {hasPermission("edit-veterinarians") && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClick()}
                  >
                    Editar
                  </Button>
                )}
              </Box>
            </Box>
            <CardContent>
              <Grid container spacing={4}>
                {/* Nombre y Apellido */}
                <Grid item xs={4}>
                  <Labeled label="Nombre y Apellido">
                    <TextField source="username" className={classes.text} />
                  </Labeled>
                </Grid>
                {/* RUT */}
                <Grid item xs={4}>
                  <Labeled label="RUT">
                    <TextField source="rut" className={classes.text} />
                  </Labeled>
                </Grid>
                {/* Estado */}
                <Grid item xs={4}>
                  <Labeled label="Estado">
                    <CustomSelect />
                  </Labeled>
                </Grid>
                {/* Teléfono */}
                <Grid item xs={4}>
                  <Labeled label="Teléfono">
                    <TextField source="phone_number" className={classes.text} />
                  </Labeled>
                </Grid>
                {/* Email */}
                <Grid item xs={8}>
                  <Labeled label="Email">
                    <TextField source="email" className={classes.text} />
                  </Labeled>
                </Grid>
                {/* Universidad */}
                <Grid item xs={4}>
                  <Labeled label="Universidad">
                    <TextField source="university" className={classes.text} />
                  </Labeled>
                </Grid>
                {/* N° de registro de colegio médico veterinario */}
                <Grid item xs={4}>
                  <Labeled label="N° de registro de colegio médico veterinario">
                    <TextField
                      source="license_number"
                      className={classes.text}
                    />
                  </Labeled>
                </Grid>
                {/* Año de graduación */}
                <Grid item xs={4}>
                  <Labeled label="Año de graduación">
                    <TextField source="grad_year" className={classes.text} />
                  </Labeled>
                </Grid>
                {/* Imagen de validación de profesión */}
                <Grid item xs={4}>
                  <Labeled label="Imagen de validación de profesión">
                    <VeterinarianImageField
                      source="certificate_url"
                      label="Certificado actual"
                    />
                  </Labeled>
                </Grid>
                {/* Firma */}
                <Grid item xs={8}>
                  <Labeled label="Firma">
                    <ImageField source="signature" />
                  </Labeled>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
