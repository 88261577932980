import React from 'react'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router'

const BackButton = ({ history: { goBack }, children, ...props }) => {
    const { staticContext, ...rest } = props;

    return (
        <Button {...rest} onClick={goBack}>
            {children}
        </Button>
    );
};

export default withRouter(BackButton);
