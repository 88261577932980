import { AppBar, UserMenu } from "react-admin";
import { Typography ,Box} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import logo from "../assets/BarLogo.svg";

const useStyles = makeStyles({
  app: {
    background: "white!important",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxHeight: "45px",
    maxWidth: "145px",
    marginBottom: 5,
    marginTop: 5,
  },
  userMenu: {
    "& button.MuiIconButton-root": {
      borderRadius: "0!important",
    },
  },
});

const MyUserMenu = ({ user, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.userMenu}>
      <UserMenu
        {...props}
        label={"Usuario"}
        icon={
          <span style={{ fontSize: "14px", color: "#5B5B5B" }}>
            {user?.name}
          </span>
        }
      ></UserMenu>
    </Box>
  );
};
export default function CustomAppBar(props) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <AppBar
      {...props}
      color="primary"
      className={classes.app}
      userMenu={<MyUserMenu user={user} />}
    >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src={logo} alt="logo" className={classes.logo} />
      <span className={classes.spacer} />
    </AppBar>
  );
}
