import * as React from "react";
import {
  Datagrid,
  Filter,
  List,
  TextField,
  SearchInput,
} from "react-admin";
import { ActionsCreate } from "../../components/ActionsCreate";
import { EmptyPage } from "../../components/EmptyPage";
import { hasPermission } from "../../utils/permissions";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  search: {
    '& input[name="search"]': {
      paddingTop: "18px",
    },
  },
});
const RoleFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SearchInput
        source="search"
        alwaysOn
        variant="filled"
        className={classes.search}
      />
    </Filter>
  );
};

export const RoleList = (props) => {
  return (
    <List
      title=" "
      filters={<RoleFilter />}
      {...props}
      sort={{ field: "name", order: "ASC" }}
      empty={<EmptyPage resourceName="Roles" />}
      actions={<ActionsCreate label="Nuevo Rol" />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={hasPermission("edit-roles") ? "edit" : ""}>
        <TextField source="name" label="Nombre" />
        <TextField source="users_count" label="Cantidad" />
      </Datagrid>
    </List>
  );
};
