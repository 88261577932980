import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
} from "@material-ui/core";
import {
  Edit,
  required,
  TextInput,
  SelectInput,
  NumberInput,
  ImageField,
  FormWithRedirect,
  SaveButton,
} from "react-admin";
import { AsidePrescriptions } from "../../../components/AsidePrescriptions";

const useStyle = makeStyles({
  mainCard: {
    borderRadius: "10px",
    padding: "1em",
  },
  mainCardHeader: {
    padding: "0",
  },
});

const CHOICES = [
  { id: "approved", name: "Aprobado" },
  { id: "pending", name: "Pendiente" },
  { id: "rejected", name: "Rechazado" },
];

const VeterinarianEditCard = props => {
  const classes = useStyle();

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Card className={classes.mainCard}>
            <Box
              display='flex'
              justifyContent='space-between'
              marginBottom='2em'
              padding='1em'
            >
              <CardHeader
                className={classes.mainCardHeader}
                title='Ficha de Veterinario'
                titleTypographyProps={{ style: { fontWeight: 600 } }}
              />
              <SaveButton
                label="guardar cambios"
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Box>
            <CardContent>
              <Box display='flex' gridGap='4em'>
                <Box display='flex' flexDirection='column' minWidth='40%'>
                  <TextInput
                    source='first_name'
                    label='Nombre'
                    variant='standard'
                    validate={required()}
                  />
                  <TextInput source='rut' label='RUT' variant='standard' />
                  <TextInput
                    source='email'
                    label='Email'
                    type='email'
                    variant='standard'
                    validate={[required()]}
                  />
                  <TextInput
                    source='phone_number'
                    label='Teléfono'
                    type='phone'
                    variant='standard'
                    validate={[required()]}
                  />

                  <TextInput
                    source='license_number'
                    label='N°de registro de colegio médico veterinario°'
                    variant='standard'
                  />
                </Box>

                <Box display='flex' flexDirection='column' minWidth='40%'>
                  <TextInput
                    source='last_name'
                    label='Apellido'
                    variant='standard'
                    validate={required()}
                  />
                  <SelectInput
                    source='status'
                    label='Estado'
                    variant='standard'
                    choices={CHOICES}
                    validate={[required()]}
                  />
                  <NumberInput
                    source='grad_year'
                    label='Año graduación'
                    variant='standard'
                  />
                  <TextInput
                    source='university'
                    label='Universidad'
                    variant='standard'
                  />
                  <Box display="flex" justifyContent="space-between" >
                    <ImageField source='certificate_url' />
                    <ImageField source='signature' />
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    />
  );
};

export const VeterinarianEdit = props => {
  return (
    <Edit
      component='div'
      {...props}
      title=" "
      actions={null}
      aside={<AsidePrescriptions />}
    >
      <VeterinarianEditCard />
    </Edit>
  );
};
