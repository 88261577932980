import {
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  DateField,
  ReferenceField,
  FunctionField,
  useGetList,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import {
  makeStyles,
  Chip,
  Card,
  CardHeader,
  Button,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Grid,
  CardContent,
} from "@material-ui/core";
import { PrescriptionAside } from "./PrescriptionAside";
import { hasRole } from "../../../utils/permissions";
import { useState } from "react";

const useStyle = makeStyles({
  main: {
    "& .MuiCardContent-root": {
      paddingTop: "0 !important",
    },
  },
  mainCard: {
    borderRadius: "10px",
    padding: "1em",
  },
  mainCardHeader: {
    marginBottom: "1em",
  },
  boldText: {
    color: "black",
    fontWeight: 500,
    fontSize: "1.1em",
  },
  boldRefText: {
    fontWeight: 500,
    fontSize: "1.1em",
  },
  success: {
    color: "#FFF",
    backgroundColor: "#1EBA82",
  },
  warning: {
    color: "#FFF",
    backgroundColor: "#FF8947",
  },
  invalid: {
    color: "#FFF",
    backgroundColor: "#C42A21",
  },
  linkButton: {
    marginRight: "2em",
  },
  pt: {
    paddingTop: "2em",
  },
  dialogTitle: {
    fontSize: "26px",
    fontWeight: 500,
  },
  dialogActions: {
    padding: "1em 0 2em 1.5em",
    justifyContent: "flex-start",
  },
});

const StatusChipField = () => {
  const classes = useStyle();
  const record = useRecordContext();
  const label =
    record.status === "sent"
      ? "ENVIADA"
      : record.status === "invalid"
      ? "ANULADA"
      : "SIN ENVIAR";

  return (
    <Chip
      label={label}
      className={
        record.status === "sent"
          ? classes.success
          : record.status === "invalid"
          ? classes.invalid
          : classes.warning
      }
    />
  );
};

const PrescriptionActions = () => {
  const classes = useStyle();
  const record = useRecordContext();

  return (
    <Box marginTop="2em">
      <Button
        variant="outlined"
        color="primary"
        href={record.prescription_url}
        target="_blank"
        rel="noreferrer"
        className={classes.linkButton}
      >
        ver receta
      </Button>

      {hasRole("Cliente") && record?.status !== "invalid" ? (
        <SendPrescriptionButton />
      ) : null}
    </Box>
  );
};

const SendPrescriptionButton = () => {
  const classes = useStyle();
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [factoryId, setFactoryId] = useState(null);
  const notify = useNotify();
  const refresh = useRefresh();

  const [mutate, { loading: isSending }] = useMutation();

  const send = (id) =>
    mutate(
      {
        type: "sendToProduction",
        resource: "recetas",
        payload: {
          id: record.id,
          data: { factory_id: id },
        },
      },
      {
        onSuccess: (response) => {
          record.status = "sent";
          refresh();
          notify("La receta ha sido enviada con éxito.", {
            type: "success",
          });
        },
        onFailure: (error) => {
          notify(`Hubo un error al enviar la receta. (${error.message})`, {
            type: "warning",
          });
        },
      }
    );

  const { data: factories, loading } = useGetList("plantas");

  const handleChange = (event) => {
    setFactoryId(event.target.value);
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    send(Number(factoryId));
    setOpen(false);
  };

  return factories ? (
    <>
      {isSending ? (
        <Box display="inline-flex" gridGap="1em" alignItems="center">
          <Typography color="primary" variant="button">
            Enviando...
          </Typography>
          <CircularProgress color="primary" size={20} />
        </Box>
      ) : (
        <Button
          disabled={record.status === "sent"}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          enviar receta
        </Button>
      )}
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle className={classes.pt}>
          <Typography className={classes.dialogTitle}>Enviar receta</Typography>
          <Box display="block">
            <Typography variant="body2">
              Selecione la planta o fábrica a la que desea enviarle la receta.{" "}
            </Typography>
          </Box>
        </DialogTitle>
        {loading ? (
          <Box
            minHeight="150px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <DialogContent>
            <FormControl>
              <RadioGroup value={factoryId} onChange={handleChange}>
                {Object.values(factories).map((factory, index) => (
                  <FormControlLabel
                    key={index}
                    value={`${factory.id}`}
                    control={<Radio color="primary" />}
                    label={factory.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
        )}
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDialogClose}
          >
            cancelar
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            enviar receta
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export const PrescriptionShow = (props) => {
  const classes = useStyle();

  return (
    <Show
      {...props}
      component="div"
      actions={null}
      aside={<PrescriptionAside />}
      className={classes.main}
      title=" "
    >
      <SimpleShowLayout redirect="list" toolbar={null}>
        <div>
          <Card className={classes.mainCard}>
            <CardHeader
              title="Receta"
              className={classes.mainCardHeader}
              titleTypographyProps={{ style: { fontWeight: 600 } }}
            />
            <CardContent>
              <Grid container spacing={4}>
                {/* Fecha */}
                <Grid item xs={4}>
                  <Labeled label="Fecha">
                    <DateField
                      locales="es-ES"
                      label="Fecha"
                      source="created_at"
                      className={classes.boldText}
                    />
                  </Labeled>
                </Grid>

                {/* Estado */}
                <Grid item xs={4}>
                  <Labeled label="Estado">
                    <StatusChipField />
                  </Labeled>
                </Grid>

                {/* Planta/Fábrica */}
                <Grid item xs={4}>
                  <Labeled label="Planta/Fábrica">
                    <ReferenceField source="factory_id" reference="plantas">
                      <TextField
                        source="name"
                        className={classes.boldRefText}
                      />
                    </ReferenceField>
                  </Labeled>
                </Grid>

                {/* Veterinario */}
                <Grid item xs={4}>
                  <Labeled label="Veterinario">
                    <ReferenceField
                      source="veterinarian_id"
                      reference="veterinarios"
                    >
                      <FunctionField
                        render={(record) =>
                          `${record.first_name} ${record.last_name}`
                        }
                        className={classes.boldRefText}
                      />
                    </ReferenceField>
                  </Labeled>
                </Grid>

                {/* Cliente */}
                {hasRole("superadmin") && (
                  <Grid item xs={4}>
                    <Labeled label="Cliente">
                      <ReferenceField
                        source="estate.client_id"
                        reference="clientes"
                        link="show"
                      >
                        <TextField
                          source="name"
                          className={classes.boldRefText}
                        />
                      </ReferenceField>
                    </Labeled>
                  </Grid>
                )}

                {/* Predio */}
                {hasRole("Cliente") && (
                  <Grid item xs={4}>
                    <Labeled label="Predio">
                      <ReferenceField
                        source="estate_id"
                        reference="predios"
                        link="list"
                      >
                        <TextField
                          source="name"
                          className={classes.boldRefText}
                        />
                      </ReferenceField>
                    </Labeled>
                  </Grid>
                )}

                {/* Código único */}
                <Grid item xs={4}>
                  <Labeled label="Código único">
                    <TextField
                      className={classes.boldText}
                      source="prescription_number"
                    />
                  </Labeled>
                </Grid>
              </Grid>

              {/* Actions */}
              <PrescriptionActions />
            </CardContent>
          </Card>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
