import { Fragment, useState } from "react";
import {
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  Button,
} from "react-admin";

import {
  makeStyles,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  TextField as MuiText,
  Button as MuiButton
} from "@material-ui/core";

const useStyles = makeStyles({
  titleDialog: {
    fontSize: "18px!important",
    fontWeight: "500!important",
    lineHeight: "20px!important",
  },
  dialogActions: {
    padding: "0 16px 16px 16px!important",
  },
  dialogContent: {
    padding: "16px!important",
  },
  rejectedText:{
    width: "100%",
    margin:'16px 0',
    "& .MuiFilledInput-root": { padding: "8px!important" },
    "& .MuiFilledInput-multiline":{ padding: "8px!important" },
  }
});

export const RejectedButton = ({ selectedIds }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("veterinarios");
  const [updateMany, { loading }] = useUpdateMany(
    "veterinarians",
    selectedIds,
    { status: "rejected", message: text, validation_date: null },
    {
      onSuccess: () => {
        refresh();
        notify("Veterinarios actualizados", { type: "info" });
        unselectAll();
      },
      onError: (error) =>
        notify("No se pudieron actualizar los veterinarios", {
          type: "warning",
        }),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };
  return (
    <Fragment>
      <Button
        label="Rechazar"
        variant="outlined"
        style={{ borderColor: "#C32A21", color: "#C32A21" }}
        disabled={loading}
        onClick={handleClick}
      />
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.titleDialog}>
            ¿Está seguro que desea rechazarlos?
          </Typography>
          <MuiText
            value={text}
            onChange={(e) => setText(e.target.value)}
            variant="filled"
            placeholder="Motivos del rechazo"
            multiline
            rows={2}
            maxRows={4}
            className={classes.rejectedText}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Box display="flex" width="100%" justifyContent="space-between">
            <MuiButton onClick={handleDialogClose}>CANCELAR</MuiButton>
            <MuiButton
              onClick={handleConfirm}
              disabled={loading}
              variant="contained"
              color="primary"
            >
              ACEPTAR
            </MuiButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
