/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Notification,
  useTranslate,
  useNotify,
  TextInput,
  required,
  email,
  FormDataConsumer,
  SimpleForm,
} from "react-admin";
import AppBarOnly from "../layouts/AppBarOnly";

const useStyles = makeStyles((theme) => ({
  body: {
    height: "660px",
    background: "linear-gradient(#007DC3 50%, transparent 50%)",
    backgroundRepeat: "no-repeat",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  card: {
    maxWidth: 400,
    marginTop: "6em",
    padding: "32px 16px",
    background: "rgba(255,255,255,1)",
    borderRadius: "10px",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    backgroundColor: "#007DC3",
    "&:hover": {
      backgroundColor: "#007DC3",
    },
  },
  hint: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  spacing: {
    marginRight: "1em",
  },
  input: {
    "& .MuiInputBase-input": {
      color: "#000", // Text color
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#007DC3",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "#000", // Semi-transparent underline
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottomColor: "#007DC3", // Solid underline on hover
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#007DC3", // Solid underline on focus
    },
  },
  title: {
    margin: "0 0 .2em 0",
    color: "#363636",
    fontSize: "26px",
    lineHeight: "30px",
    // fontFamily: "Fira Sans",
  },
  subtitle: {
    fontSize: "14px",
    lineHeight: "16px",
    // fontFamily: "Fira Sans",
  },
}));

const SimpleFields = ({ handleSubmit, loading }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.form}>
                <h1 className={classes.title}>
                  {translate("ra.forgot.title")}
                </h1>
                <span className={classes.subtitle}>
                  {translate("ra.forgot.subtitle")}
                </span>
              </div>
              <div className={classes.form}>
                <TextInput
                  name="email"
                  source="email"
                  label={translate("ra.register.email")}
                  disabled={loading}
                  className={classes.input}
                  validate={[email(), required()]}
                  fullWidth
                />
              </div>
              <CardActions className={classes.actions}>
                <Button
                  className={classes.icon}
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  onClick={(e) => {
                    handleSubmit(formData);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                      className={classes.spacing}
                    />
                  )}
                  {translate("ra.forgot.button")}
                </Button>
              </CardActions>
            </Card>
            <Notification />
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ marginTop: "20px", textAlign: "center" }}
            >
              El Token tiene una válidez de 60 minutos. Si se vence puede volver
              a solicitarlo.
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ textAlign: "center" }}
            >
              Si no ha recibido el email, por favor revise su carpeta de SPAM.
            </Typography>
          </div>
        );
      }}
    </FormDataConsumer>
  );
};

const Recover = (props) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = async (values) => {
    setLoading(true);
    if (values?.email) {
      try {
        const url =
          process.env.REACT_APP_API_HOST_ADMIN + `/auth/forgot-password`;
        const body = JSON.stringify(values);
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        })
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            if (!json?.error) {
              notify(
                "Se ha enviado un email para cambiar la contraseña",
                "info"
              );
              setLoading(false);
            } else {
              setLoading(false);
              notify(json?.error, "warning");
            }
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      setLoading(false);
      notify(translate("ra.validation.email"), "warning");
    }
  };

  return (
    <div className={classes.body}>
      <AppBarOnly />
      <SimpleForm toolbar={null}>
        <SimpleFields handleSubmit={handleSubmit} loading={loading} />
      </SimpleForm>
    </div>
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const CustomRecover = (props) => <Recover {...props} />;

export default CustomRecover;
