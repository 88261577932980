import { 
  Box, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  makeStyles, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography,
  Button
} from "@material-ui/core";
import { 
  DeleteWithConfirmButton, 
  EditButton, 
  ImageField, 
  Labeled, 
  ListButton, 
  Show, 
  SimpleShowLayout, 
  TextField, 
  TopToolbar, 
  UrlField, 
  useRecordContext 
} from "react-admin";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { hasPermission } from "../../../utils/permissions";
import { Fragment, useState } from "react";

const useStyle = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column'
  },
  topToolbar: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    color: '#000',
    marginBottom: '1em'
  },
  boldText: {
    color: "black",
    fontWeight: 500,
    fontSize: "1.1em",
  },
  boldUrlText: {
    fontWeight: 500,
    fontSize: "1.1em",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  pointer: {
    cursor: "pointer"
  }
});

const ProductShowActions = ({ basePath, data, resource }) => {
  const classes = useStyle();

  return basePath && data ? (
    <TopToolbar className={classes.topToolbar}>
      <ListButton icon={<ChevronLeftIcon />} size="medium" label="Volver" basePath={basePath} />
        <Box display="flex" gridGap="1rem">
          {hasPermission('edit-products') && <EditButton basePath={basePath} record={data} />}
          {hasPermission('delete-products') && <DeleteWithConfirmButton basePath={basePath} record={data} confirmTitle={`Borrar medicamento ${data?.name}`} />}
        </Box>
    </TopToolbar>
  ) : null;
};

const ProductDetailsTable = () => {
  const record = useRecordContext();
  if (!record) return null;
  const { details } = record;

  return (
    <Box
      display='flex'
      flexDirection='column'
      margin='0.5em 0'
    >
      <Typography variant="caption">
        Factores y constantes
      </Typography>
      <Box maxWidth="400px" marginTop="1em">
      {
        details ? (
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="Details table">
              <TableHead>
                <TableRow>
                  <TableCell>Propiedad</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(details).map(key => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell align="left">{details[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : <Typography variant="caption">Sin información</Typography>
      }
      </Box>
    </Box>
  );
};

const DosesImageField = ({ source, label }) => {
  const classes = useStyle();
  const record = useRecordContext();

  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  return record.doses_url ? (
    <Fragment>
      <ImageField
        source={source}
        label={label}
        onClick={handleClick}
        className={classes.pointer}
      />
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle className={classes.pt}>
          <Typography variant="body2">
            Tabla de dosis
          </Typography>
        </DialogTitle>
        <DialogContent>
          <img
            className={classes.image}
            src={record.doses_url}
            alt="doses"
          />
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogClose}
          >
            cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  ) : <Typography component="span" variant="body">
        sin datos
      </Typography>;
};

export const ProductShow = (props) => {
  const classes = useStyle();

  return (
    <Show {...props}  title=" " component="div" actions={<ProductShowActions />} >
      <SimpleShowLayout>
        <div className={classes.main}>
          <Typography className={classes.title} variant="h5">Etiqueta</Typography>
          <Labeled label="Nombre del medicamento">
            <TextField source="name" className={classes.boldText} />
          </Labeled>
          <Labeled label="Principio activo">
            <TextField emptyText="sin datos" source="active_principle" className={classes.boldText} />
          </Labeled>
          <Labeled label="N° Reg SAG">
            <TextField emptyText="sin datos" source="register_number" className={classes.boldText} />
          </Labeled>
          <Labeled label="Ficha técnica">
            <UrlField emptyText="sin datos" source="data_sheet_url" target="_blank" className={classes.boldUrlText} />
          </Labeled>
          <Labeled label="Tabla de dosis">
            <DosesImageField source="doses_url" label="Tabla de dosis" />
          </Labeled>
          <ProductDetailsTable />
        </div>
      </SimpleShowLayout>
    </Show>
  )
};
