import { makeStyles } from "@material-ui/core";
import { Filter, SearchInput, SelectInput } from "react-admin";
const CHOICES = [
  { id: "approved", name: "Aprobado" },
  { id: "pending", name: "Pendiente" },
  { id: "rejected", name: "Rechazado" },
];
const useStyles = makeStyles({
  search: {
    '& input[name="search"]':{
      paddingTop: '18px',
    }
  }
})
export const VeterinarianFilter = props => {
  const classes = useStyles();
  return(
  <Filter {...props} variant='outlined'>
    <SearchInput source='search' alwaysOn variant="filled" className={classes.search}/>
    <SelectInput source="status" label="Estado" choices={CHOICES} alwaysOn variant="filled"/>
  </Filter>
  );
}
