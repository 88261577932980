import { Resource } from 'react-admin';

import { UserList, UserCreate , UserEdit} from '../resources/users';
import { NonPermission } from '../components/NonPermission';
import { hasPermission } from '../utils/permissions';
import { VeterinarianList, VeterinarianEdit, VeterinarianShow} from "../resources/veterinarians";
import { PrescriptionList, PrescriptionShow} from "../resources/prescriptions";
import { ClientList, ClientShow} from "../resources/clients";
import { FactoryList, FactoryCreate, FactoryEdit} from "../resources/factories";
import { RoleList, RoleCreate , RoleEdit} from '../resources/roles';
import { ProductList, ProductCreate,ProductEdit, ProductShow } from '../resources/products';
import { EstateList } from '../resources/estates';

export const Resources = () => [
    <Resource name="recetas" 
        list={hasPermission('list-prescriptions') ? PrescriptionList : null}
        show={hasPermission('show-prescriptions') ? PrescriptionShow : NonPermission}
    />,
    <Resource name='usuarios' 
        list={hasPermission('list-users') ? UserList : null}
        edit={hasPermission('edit-users') ? UserEdit : NonPermission}
        create={hasPermission('create-users') ? UserCreate : NonPermission}
    />,
    <Resource name="veterinarios" 
        list={hasPermission('list-veterinarians') ? VeterinarianList : null}
        edit={hasPermission('edit-veterinarians') ? VeterinarianEdit : NonPermission}
        show={hasPermission('show-veterinarians') ? VeterinarianShow : NonPermission}
    />,
    <Resource name="clientes" 
        list={hasPermission('list-clients') ? ClientList : null}
        show={hasPermission('show-clients') ? ClientShow : NonPermission}
    />,
    <Resource name="predios"
        list={hasPermission('list-estates') ? EstateList : null}
    />,
    <Resource name="plantas" 
        list={hasPermission('list-factories') ? FactoryList : null}
        create={hasPermission('create-factories') ? FactoryCreate : NonPermission}
        edit={hasPermission('edit-factories') ? FactoryEdit : NonPermission}
    />,
    <Resource name="roles" 
        list={hasPermission('list-roles') ? RoleList : null} 
        create={hasPermission('create-roles')? RoleCreate : NonPermission} 
        edit={hasPermission('edit-roles') ? RoleEdit : NonPermission}/>,
    <Resource name="etiquetas" 
        list={hasPermission('list-products') ? ProductList : null} 
        create={hasPermission('create-products')? ProductCreate : NonPermission} 
        show={hasPermission('show-products') ? ProductShow : NonPermission}
        edit={hasPermission('edit-products') ? ProductEdit : NonPermission}
    />,
    <Resource name="habilidades" />,
  
]

export default Resources;