import { makeStyles } from "@material-ui/core";
import { Filter, SearchInput, TextInput } from "react-admin";

const useStyles = makeStyles({
  search: {
    '& input[name="search"]': {
      paddingTop: "18px",
    },
  },
});

export const ClientFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} variant='filled'>
      <SearchInput source='search'  alwaysOn variant='filled' className={classes.search} />
      <TextInput source='rut' label='RUT' alwaysOn variant='filled' />
    </Filter>
  );
};
