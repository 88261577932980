import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
  link:{
    textDecoration: 'none',
    color: '#007DC3',
    textTransform: 'capitalize',
    '&:hover':{
      textDecoration: 'none',
      cursor: 'pointer',
    }
  }
})

export const EstatesPanel = ({ record }) => {
  const classes = useStyles();
  return (
    <Box display='flex' pl='2.5em' pt='2em'>
      <Box
        mr='2em'
        minWidth='280px'
        display='flex'
        flexDirection='column'
      >
        <Box ml='1em'>
          <Typography variant='h6' color='textSecondary' gutterBottom>
            {record?.estates_count === 0 ? 'Sin predios' : 'Predios'}
          </Typography>
        </Box>
        <Box mb='3em'>
          <TableContainer elevation={3}>
            <Table size='small' aria-label='stock'>
              <TableBody>
                {record?.estates?.map(estate => (
                  <TableRow key={estate.id}>
                    <TableCell scope='row'>
                    <Link
                      className={classes.link}
                      to={{
                        pathname: "/recetas",
                        search: `filter=${JSON.stringify({ estate_id: estate.id })}`,
                      }}
                    >{estate.name}</Link>
                    </TableCell>
                    <TableCell align='right'>{estate.prescriptions_count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};
