import {
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  EmailField,
} from "react-admin";
import {
  Box,
  makeStyles,
  Card,
  CardHeader,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import { ClientAside } from "./ClientAside";

const useStyle = makeStyles({
  mainCard: {
    borderRadius: "10px",
    padding: "0 0.5em",
  },
  mainCardHeader: {
    marginBottom: '1em'
  },
  title: {
    color: "#ABABAB",
  },
  boldText: {
    color: "black",
    fontWeight: 500,
    fontSize: "1.1em",
  },
  boldEmailText: {
    fontWeight: 500,
    fontSize: "1.1em",
  },
  tableTitle: {
    marginBottom: "1em",
  },
  name: { gridArea: "name" },
  rut: { gridArea: "rut" },
  email: { gridArea: "email" },
  grid: {
    width: "100%",
    display: "grid",
    gridGap: "2em",
    gridTemplateAreas: `"name rut email" "estates estates estates"`,
  },
});

const EstatesTable = () => {
  const classes = useStyle();
  const record = useRecordContext();
  const { estates } = record;

  return (
    <Box
      display='flex'
      flexDirection='column'
      margin='0.5em 0'
      gridArea='estates'
    >
      <Typography className={`${classes.tableTitle} ${classes.boldText}`}>
        Predios de {record.name}
      </Typography>
      <Table>
        <TableBody>
          {estates.map(item => (
            <TableRow key={item.name}>
              <TableCell align='left'>{item.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export const ClientShow = props => {
  const classes = useStyle();

  return (
    <Show {...props}  title=" " component='div' actions={null} aside={<ClientAside />}>
      <Card className={classes.mainCard}>
        <CardHeader title='Ficha de Cliente' className={classes.mainCardHeader} titleTypographyProps={{ style: { fontWeight: 600 } }} />
        <SimpleShowLayout
          redirect='list'
          variant='standard'
          margin='dense'
          toolbar={null}
        >
          <div className={classes.grid}>
            {/* Razón social */}
            <Labeled label='Razón social' className={`${classes.title} ${classes.name}`}>
              <TextField source='name' className={classes.boldText} />
            </Labeled>
            {/* RUT */}
            <Labeled label='RUT' className={`${classes.title} ${classes.rut}`}>
              <TextField source='rut' className={classes.boldText} />
            </Labeled>
            {/* Email */}
            <Labeled label='Email' className={`${classes.title} ${classes.email}`}>
              <EmailField source='email' className={classes.boldEmailText} />
            </Labeled>
            {/* Predios */}
            <EstatesTable />
          </div>
        </SimpleShowLayout>
      </Card>
    </Show>
  );
};
