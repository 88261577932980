import { Layout } from "react-admin";
import { Menu } from './Menu';
import CustomAppBar from "./CustomAppBar";

export const CustomLayout = props => (
  <Layout {...props}
    menu={Menu}
    appBar={CustomAppBar}
  />
);
