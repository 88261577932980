import { List, Datagrid, TextField } from "react-admin";
import { ProductFilter } from "./ProductFilter";
import { EmptyPage } from "../../../components/EmptyPage";
import { ListActions } from "../../../components/ListActions";

export const ProductList = (props) => (
  <List
    {...props}
    empty={<EmptyPage resourceName="Etiquetas" />}
    title=" "
    perPage={10}
    sort={{ field: "name", order: "ASC" }}
    filters={<ProductFilter />}
    bulkActionButtons={false}
    actions={<ListActions hasCreate={true} labelButton="nueva etiqueta" />}
  >
    <Datagrid title="Etiquetas" rowClick="show">
      <TextField source="name" label="Nombre" />
    </Datagrid>
  </List>
);
