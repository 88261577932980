import { Fragment, useState } from 'react';
import {
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    Button,
    Confirm,
} from 'react-admin';

export const ApproveButton = ({selectedIds}) => {
   
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('veterinarios');
    const [updateMany, { loading }] = useUpdateMany(
        'veterinarians',
        selectedIds,
        {  status: 'approved', verification_token:null, validation_date: new Date().toISOString().slice(0, 19).replace('T', ' ') },
        {
            onSuccess: () => {
                refresh();
                notify('Veterinarios actualizados');
                unselectAll();
            },
            onError: error => notify('No se pudieron actualizar los veterinarios', { type: 'warning' }),
        }
    );
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };
    return (
        <Fragment>
            <Button
                label="Aprobar"
                variant='outlined'
                style={{borderColor:'#007DC3', color:'#007DC3'}}
                disabled={loading}
                onClick={handleClick}
            />
             <Confirm
                isOpen={open}
                loading={loading}
                title={"Confirmar acción"}
                content={"¿Está seguro que desea modificar el estado de los veterinarios?"}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};
