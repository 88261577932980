import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useVersion, useDataProvider } from 'react-admin';
import { useField } from 'react-final-form';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold,
    },
}));

export default function Abilities({ record, source }) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [state, setState] = useState({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const { input } = useField(source);

    const fetchAbilities = useCallback(async () => {
        const { data: allAbilities } = await dataProvider.getList(
            'abilities',
            {
                filter: {},
                sort: { field: 'name', order: 'DESC' },
                pagination: { page: 1, perPage: 200 },
            }
        );
        setState(state => ({
            ...state,
            allAbilities,
        }));
    }, [dataProvider]);

    useEffect(() => {
        fetchAbilities();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (record && record.abilities) {
            setChecked(record.abilities);
        }
    }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        input.onChange(newChecked);
    };

    return (
        <List className={classes.root}>
            <Typography className={classes.heading}>Permisos</Typography>
            {state.allAbilities && state.allAbilities.map((value) => {
                const labelId = `checkbox-list-label-${value.id}`;

                return (
                    <ListItem key={value.id} dense button onClick={handleToggle(value.id)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(value.id) !== -1}
                                tabIndex={-1}
                                color="primary"
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId}
                            primary={value.title}
                            secondary={value.name} />
                    </ListItem>
                );
            })}
        </List>
    );
}