import { useDispatch } from "react-redux";
import {
  showNotification,
  CREATE,
  SaveButton,
  useRedirect,
  useEditContext,
} from "react-admin";
import { useFormState } from "react-final-form";
export const SaveCreate = ({value = 'name', labelSave = 'GUARDAR' ,...props}) =>{
    const { resource,  } = useEditContext();
    const useredirect = useRedirect();
    const dispatch = useDispatch();
    const { values } = useFormState();
    const handleSave = (item) => {
        dispatch({
          type: "CREATE",
          payload: { data: values },
          meta: {
            fetch: CREATE,
            resource: resource,
            onSuccess: {
              callback: ({ payload: { data } }) => {
                dispatch(showNotification(data[value] + " modificado con éxito", "info"));
                useredirect(props?.basePath);
              },
            },
            onFailure: {
              callback: ({ message }) => {
                let errorMessages = [];
                Object.keys(message).forEach(key => {
                  errorMessages.push(message[key]);
                });
                errorMessages = errorMessages.join(',');
                dispatch(showNotification(errorMessages, "error"));
              },
            },
          },
        });
    }
    return (
      <SaveButton handleSubmitWithRedirect={handleSave} label={labelSave} {...props}/>
    )
  }