
import {
    TopToolbar,
} from 'react-admin';
import BackButton from './BackButton';

export const ActionsBack = ({ redirect,props }) => {
    return (
    <TopToolbar style={{justifyContent:'flex-start'}}>
        <BackButton redirect={redirect}>Volver</BackButton>
    </TopToolbar>
    )
};

