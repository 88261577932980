import {
  SimpleForm,
  TextInput,
  Create,
  required,
  NumberInput,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer
} from "react-admin";
import {Box, makeStyles, Typography} from "@material-ui/core";

const useStyle = makeStyles({
  title: {
    fontSize: '1.6em',
    fontWeight: 500,
    marginBottom: '1em'
  },
  cancelButton: {
    marginRight: '2em'
  },
  fileInput: {
    maxWidth: "300px"
  }
});

const ProductCreateToolbar = (props) => {
  const classes = useStyle();

  return (
  <Toolbar {...props}>
    <ListButton icon={null} className={classes.cancelButton} variant="outlined" size="medium" label="cancelar" basePath={props.basePath} />
    <SaveButton
      label="agregar etiqueta"
      redirect="list"
      submitOnEnter={false}
    />
  </Toolbar>
)};

export const ProductCreate = (props) => {
  const classes = useStyle();
 
  const transform = data => {
    if (!data.details_array) return data;

    const details_obj = {};

    for (let i = 0; i < data.details_array.length; i++) {
      const property = Object.values(data.details_array[i])[0];
      const formattedProperty = property.toLowerCase().replace(/ /g, "_");
      const value = Object.values(data.details_array[i])[1];
      details_obj[formattedProperty] = value;
    };

    return {
      ...data,
      details_obj
    }
  };

  return (
    <Create {...props} component='div'  title=" " transform={transform} redirect="list">
      <SimpleForm toolbar={<ProductCreateToolbar />}>
        <Typography className={classes.title}>
          Nueva etiqueta
        </Typography>
        <TextInput source="name" label="Nombre del medicamento" validate={[required()]} />
        <TextInput source="active_principle" label="Principio activo" />
        <TextInput source="register_number" label="N° Reg SAG" />
        <FileInput className={classes.fileInput} source="data_sheet_raw" label="Subir ficha técnica" accept="application/pdf">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput className={classes.fileInput} source="doses_raw" label="Subir tabla de dosis" accept="image/jpg,image/bmp,image/png">
          <FileField source="src" title="title" />
        </FileInput>
        <ArrayInput source="details" label="Factores y constantes" >
          <SimpleFormIterator
            disableReordering={true} 
            getItemLabel={(index) => ''}>
            <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                <Box display="flex" marginLeft="-1em" minWidth="500px">
                  <Box marginRight="1em">
                    <TextInput source={getSource('property')} record={scopedFormData} label="Propiedad" />
                  </Box>
                  <NumberInput source={getSource('value')} record={scopedFormData} label="Valor" />
                </Box>
              )
            }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
};
