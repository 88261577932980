import {
  Box,
  makeStyles,
  CardContent,
  Card,
  CardHeader,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
  aside: {
    minWidth: "250px",
    marginLeft: "1.5em",
  },
  clients: {
    fontWeight: 600,
    fontSize: "1.1em",
    marginBottom: "8px",
  },
  subtitle: {
    color: "#5B5B5B",
    fontSize: "0.9em",
  },
  prescriptions: {
    color: "#007DC3",
    fontSize: "1.5em",
    fontWeight: 600,
  },
  card: {
    borderRadius: "10px",
  },
  mt: {
    marginTop: "1.5em",
  },
  color:{
    color: "#007DC3",
    textDecoration:'none',
    cursor: 'pointer',
  },
  info:{
    color: "#5B5B5B",
    fontSize: "0.9em",
    textTransform: 'capitalize'
  },
  mg:{
      marginTop: '8px',
  }
});

export const AsidePrescriptions = ({ props }) => {
  const classes = useStyle();
  const record = useRecordContext();
  if (!record) return null;
  const { clients, prescriptions } = record;
  const estates =
    clients?.reduce((acc, item) => {
      if (item?.estates) return acc + item?.estates?.length;
      else return acc;
    }, 0);
  return (
    <Box className={classes.aside}>
      <Card className={classes.card}>
        <CardHeader
          title="Historial de recetas"
          titleTypographyProps={{ style: { fontWeight: 600 } }}
        />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <span className={classes.clients}>
                {clients?.length} Cliente{clients?.lenght !== 1 ? "s" : ""}
              </span>
              <span className={classes.subtitle}>
                {estates} Predio{estates !== 1 ? "s" : ""}
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <span className={classes.prescriptions}>
                {prescriptions?.length}
              </span>
              <span className={classes.subtitle}>
                Receta{prescriptions?.length !== 1 ? "s" : ""}
              </span>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {prescriptions?.length > 0 ? (
        <Card className={`${classes.card} ${classes.mt}`}>
          <Stepper orientation="vertical">
            {prescriptions?.slice(0)?.reverse()?.map((prescription) => (
              <Step key={`${prescription.id}`} active expanded>
                <StepLabel
                  StepIconComponent={() => (
                    <AssignmentIcon className={classes.color} />
                  )}
                >
                    <Box display="flex" flexDirection="column">
                  <Link to={`/recetas/${prescription.id}/show`} className={`${classes.color} ${classes.mg}`}>
                    {new Date(prescription.created_at).getDate()} <span style={{textTransform: 'capitalize'}}>{new Date(prescription.created_at).toLocaleString('es-AR',{month: "long"})}</span> {new Date(prescription.created_at).getFullYear()}
                  </Link>
                  <span className={classes.info}>{prescription?.estate?.client?.name} / {prescription?.estate?.name}</span>
                    </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Card>
      ) : null}
    </Box>
  );
};
