import { makeStyles } from "@material-ui/core";
import { Filter, SearchInput } from "react-admin";

const useStyles = makeStyles({
  search: {
    '& input[name="search"]': {
      paddingTop: "18px",
    },
  },
});

export const ProductFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} variant='filled'>
      <SearchInput source='search' alwaysOn variant='filled' className={classes.search} />
    </Filter>
  );
};
