import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../assets/BarLogo.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        boxShadow:  'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    logo: {
        maxHeight: '45px',
        maxWidth: "145px",
        marginBottom: 5,
        marginTop: 5,
    },
   
}));

export default function AppBarOnly() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" color='inherit'>
                <Toolbar variant="dense">
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />
                    <img src={logo} alt="logo" className={classes.logo} />
                    <span className={classes.spacer} />
                </Toolbar>
            </AppBar>
        </div>
    );
}