import { AutocompleteInput, Filter, ReferenceInput, SelectInput } from "react-admin";
import { hasRole } from "../../../utils/permissions";

export const PrescriptionFilter = props => (
  <Filter {...props} variant='filled'>
    <SelectInput label="Estado" source="sent_status" alwaysOn choices={[
      { id: 'sent', name: 'Enviadas' },
      { id: 'not-sent', name: 'Sin enviar' },
      { id: 'invalid', name:'Anulada'}
    ]} />
    <ReferenceInput label="Veterinario" source="veterinarian_id" reference="veterinarios" alwaysOn>
      <AutocompleteInput optionText="last_name" resettable />
    </ReferenceInput>
    {hasRole('superadmin') &&<ReferenceInput label="Cliente" source="estate.client_id" reference="clientes" alwaysOn>
      <AutocompleteInput optionText="name" resettable />
    </ReferenceInput>}
    <ReferenceInput label="Predio" source="estate_id" reference="predios" alwaysOn>
      <AutocompleteInput optionText="name" resettable />
    </ReferenceInput>
  </Filter>
);
