import { fetchUtils } from 'react-admin';

export const apiUrl = process.env.REACT_APP_API_HOST_ADMIN;

export const httpClient = (url, options = {}) => {
    const token = localStorage.getItem('token');
    if (!options.headers) {
        if (token) {
            options.headers = new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            })
        }else{
            options.headers = new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            });
        }
    }

    return fetchUtils.fetchJson(url, options);
}

export const sendAsFormData = (method, resource, params) => {
    const formData = new FormData();
    for (const name in params.data) {
        formData.append(name, params.data[name] ? params.data[name] : '');
    }

    if (resource === 'products') {
        if (params.data.data_sheet_raw) {
            formData.set('data_sheet_file', params.data.data_sheet_raw.rawFile);
        }
        if (params.data.doses_raw) {
            formData.set('doses_file', params.data.doses_raw.rawFile);
        }
        if (params.data.details_obj) {
            formData.set('details', JSON.stringify(params.data.details_obj));
        }
    }

    if (method === 'PUT') {
        formData.append('_method', 'PUT')
        resource = `${resource}/${params.id}`;
        method = 'POST';
    }

    const options = buidRequestOptions({
        ...params,
        method: method,
        body: formData,
    })
    delete options['data'];
    delete options.headers['Content-Type'];

    return fetch(`${apiUrl}/${resource}`, options)
        .then(res => res.json())
        .then(json => ({
            data: { ...params.data, id: json.id, response: json },
        }))
};

const buidRequestOptions = (options) => {
    const token = localStorage.getItem('token');
    const customHeaders = options.headers ? options.headers : {},
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        optionsHeaders = { ...headers, ...customHeaders };

    options.headers = optionsHeaders;
    options.mode = 'cors'

    return options
}

