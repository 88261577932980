import { createTheme } from '@material-ui/core/styles';

export const customTheme = createTheme({
  palette: {
    primary: {
      main: '#007DC3'
    },
    secondary: {
      main: '#1fa081',
    },
    error: {
      main: '#FF0000'
    },
    warning: {
      main: '#E59800'
    },
    success: {
      main:'#2AB572'
    },
    grey: {
      main: '#818181'
    },
    greydark:{
      main: '#343434'
    },
    background: {
      main: '#F5F5F5'
    },
    tonalOffset: 0.2,
    contrastThreshold: 3,
  },
  main:{
    background: '#F5F5F5'
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      'Fira Sans',
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: '#007DC3',
      },
    },
    MuiToolbar: {
        // color: 'white',
        // background: '#007DC3',
    },
    MuiAppBar:{
      root:{
        color:'#5B5B5B!important'
      }
    },
    RaLayout:{
      content:{
        backgroundColor: '#F5F5F5',
        padding: '1em !important',
        boxSizing: 'border-box',
        color: '#5B5B5B'
      },
     
    },
    MuiDrawer: {
      paper: {
        '& div:nth-child(1)': {
          position: 'relative',
        }
      }
    },
    RaMenuItemLink: {
      root: {
          borderLeft: '3px solid #fff',
          '&:hover':{
            background: '#007DC3',
          color: 'white',
          borderLeft: '3px solid #007DC3',
          '& svg':{
            color:'white',
          },
        },
      },
      active: {
        background: '#007DC3',
        color: 'white',
        borderLeft: '3px solid #007DC3',
        '& svg':{
          color:'white',
        },
        }
    },
    RaBulkActionsToolbar:{
      toolbar:{
        backgroundColor: 'white',
        color: '#232323',
        borderRadius: '10px 10px 0px 0px',
        boxShadow: '0px 1px 2px rgb(0 0 0 / 0.23)'
      }
    },
    MuiSelect:{
      root:{
        backgroundColor: 'white',
        '&:hover':{
          backgroundColor: 'white'
        },
        '&.Mui-focused':{
          backgroundColor: 'white',
        }
      },
      select:{
        '&:focus':{
          backgroundColor: 'white'
        }
      }
    },
    MuiFilledInput:{
      root:{
        backgroundColor: 'white',
        '&:hover':{
          backgroundColor: 'white',
        },
        '&.Mui-focused':{
          backgroundColor: 'white',
        }
      },
      underline:{
        '&:after':{
          borderBottomColor: '#007DC3!important',
        }
      }
    }
  },
});
