import {
    List,
    Datagrid,
    TextField,
  } from "react-admin";
  import { UserFilter } from "./UserFilter";
  import { EmptyPage } from "../../../components/EmptyPage";
  import { ListActions } from "../../../components/ListActions";

  export const UserList = props => {
    return (
      <List
        {...props}
        empty={<EmptyPage resourceName='Usuarios' />}
        title=" "
        perPage={10}
        sort={{ field: "name", order: "ASC" }}
        filters={<UserFilter />}
        bulkActionButtons={false}
        actions={<ListActions labelButton="Nuevo usuario"/>}
      >
        <Datagrid title='Usuarios' rowClick="edit">
          <TextField source='name' label='Nombre' />
          <TextField source='email' label='Email' />
          <TextField source='phone' label='Teléfono' />
        </Datagrid>
      </List>
    );
  };
  