import * as React from 'react';
import { useState } from 'react';
import { useNotify, Button } from 'react-admin';
import { apiUrl } from '../utils/commosHttp';
import GetAppIcon from '@material-ui/icons/GetApp';

const ExcelExportButton = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);

    const url = `${apiUrl}/prescriptions/export`;
    const token = localStorage.getItem('token');

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/xlsx',
      Authorization: `Bearer ${token}`,
    };

    fetch(url, { headers })
      .then(res => res.ok && res.blob())
      .then(blob => {
        const file = window.URL.createObjectURL(blob);

        if (file) {
          const a = document.createElement('a');
          a.href = file;

          let date = new Date().toLocaleString('es-AR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
          
          a.download = date + ` - reporte-recetas.xlsx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

          notify('Reporte generado con éxito', { type: 'success' });
        }
      })
      .catch(e => {
        notify(`Error en reporte: ${e}`, { type: 'error' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Button
      variant='contained'
      color='primary'
      label='Reporte'
      onClick={handleClick}
      disabled={loading}
      startIcon={<GetAppIcon />}
    />
  );
};

export default ExcelExportButton;
