import { SimpleForm, TextInput, Create, required } from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { CustomToolbar } from "../../../components/CustomToolbar";

const CustomTitle = ({label, variant}) => (
  <Box margin="1em 0">
    <Typography variant={variant}>{label}</Typography>
  </Box>
);

export const FactoryCreate = (props) => {
  return (
    <Create
      {...props}
      component="div"
      title=" "
      actions={null}
      redirect="list"
    >
      <SimpleForm redirect="list" variant="filled" margin="dense" toolbar={<CustomToolbar labelSave={"AGREGAR PLANTA"}/>}>
        <Box minWidth="400px" display="flex" flexDirection="column">
          <CustomTitle label="Nueva Planta" variant="h5" />

          <TextInput source="name" label="Nombre" validate={required()} />
          <TextInput source="code" label="Código" />
          <TextInput source="rut" label="RUT" validate={required()} />
          <TextInput source="address" label="Dirección" />

          <CustomTitle label="Encargados de la planta" variant="h6" />
          <TextInput source="in_charge_name" label="Nombre encargado 1" />
          <TextInput
            source="in_charge_email"
            label="Email encargado 1"
            type="email"
            validate={[required()]}
          />
          <TextInput
            source="in_charge_phone_number"
            label="Teléfono encargado 1"
            type="phone"
          />
          <TextInput source="in_charge2_name" label="Nombre encargado 2" />
          <TextInput source="in_charge2_email" label="Email encargado 2" type="email" />
          <TextInput
            source="in_charge2_phone_number"
            label="Teléfono encargado 2"
            type="phone"
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
